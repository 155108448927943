import './footer.scss';

function Footer() {

  return (
    <div className='footer'>
      <div className='container footer-container'>
        <div className='footer-item place'>
          <h6>Consegne</h6>
          <ul>
            <li>
              Saronno - &nbsp;
            </li>
            <li>
              Rovello Porro - &nbsp;
            </li>
            <li>
               Rovellasca - &nbsp;
            </li>
            <li>
              Cogliate - &nbsp;
            </li>
            <li>
              Misinto - &nbsp;
            </li>
            <li>
              Ceriano laghetto
            </li>
          </ul> 
        </div>
        <div className='footer-item'>
          <h6>Indirizzo</h6>
          <p>Via Larga 6, Saronno</p>
        </div>
        <div className='footer-item'>
          <h6>Social</h6>
          <a href="https://www.instagram.com/prontopizzasaronno?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className="social instagram"></a>
          <a href="https://www.facebook.com/profile.php?id=100057506474941" className="social facebook"></a>
        </div>
      </div>
    </div>
  );
}

export default Footer;