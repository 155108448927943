


import './menu.scss';

function Menu() {

  // fetch('http://localhost:3001/menu')
  // .then(response => response.json())
  // .then(data => console.log(data))
  // .catch(error => console.error(error));

  const menu = [
    {
        "": "",
        "name": "Marinara",
        "description": "pomodoro, aglio, basilico",
        "price": "4.5"
    },
    {
        "": "",
        "name": "Biancaneve",
        "description": "mozzarella, basilico",
        "price": "4.5"
    },
    {
        "": "",
        "name": "Margherita",
        "description": "pomodoro, mozzarella, basilico",
        "price": "5"
    },
    {
        "": "",
        "name": "Romana",
        "description": "pomodoro, mozzarella, olive",
        "price": "6"
    },
    {
        "": "",
        "name": "Pugliese",
        "description": "pomodoro, mozzarella, cipolla",
        "price": "6"
    },
    {
        "": "",
        "name": "Prosciutto",
        "description": "pomodoro, mozzarella, prosciutto",
        "price": "6"
    },
    {
        "": "",
        "name": "Carciofi",
        "description": "pomodoro, mozzarella, carciofi",
        "price": "6"
    },
    {
        "": "",
        "name": "Funghi",
        "description": "pomodoro, mozzarella, funghi",
        "price": "6"
    },
    {
        "": "",
        "name": "Napoli",
        "description": "pomodoro, mozzarella, capperi, acciughe",
        "price": "6"
    },
    {
        "": "",
        "name": "Wurstel",
        "description": "pomodoro, mozzarella, wurstel",
        "price": "6"
    },
    {
        "": "",
        "name": "Estiva",
        "description": "pomodoro, mozzarella, pomodoro a fette, basilico",
        "price": "6"
    },
    {
        "": "",
        "name": "Siciliana",
        "description": "pomodoro, acciughe, capperi, olive",
        "price": "6"
    },
    {
        "": "",
        "name": "Calzone liscio",
        "description": "pomodoro, mozzarella, prosciutto",
        "price": "6"
    },
    {
        "": "",
        "name": "Bacon",
        "description": "pomodoro, mozzarella, uovo, pancetta",
        "price": "6"
    },
    {
        "": "",
        "name": "Lory",
        "description": "pomodoro, mozzarella, panna, prosciutto",
        "price": "6"
    },
    {
        "": "",
        "name": "Milanese",
        "description": "pomodoro, mozzarella, salsiccia",
        "price": "6"
    },
    {
        "": "",
        "name": "Diavola",
        "description": "pomodoro, mozzarella, salame piccante, olive",
        "price": "6"
    },
    {
        "": "",
        "name": "Prosciutto e funghi",
        "description": "pomodoro, mozzarella, prosciutto, funghi",
        "price": "6"
    },
    {
        "": "",
        "name": "4 Stagioni",
        "description": "pomodoro, mozzarella, funghi, carciofi, prosciutto, capriccio di verdure",
        "price": "6"
    },
    {
        "": "",
        "name": "Tonno & Cipolla",
        "description": "pomodoro, mozzarella, tonno, cipolla",
        "price": "6"
    },
    {
        "": "",
        "name": "Bud Spencer",
        "description": "pomodoro, mozzarella, fagioli, cipolla, pancetta",
        "price": "6"
    },
    {
        "": "",
        "name": "Patatine",
        "description": "Pomodoro, mozzarella, patatine",
        "price": "6"
    },
    {
        "": "",
        "name": "Affettati",
        "description": "a scelta",
        "price": "6.25"
    },
    {
        "": "",
        "name": "4 Formaggi",
        "description": "pomodoro, mozzarella, briè, zola, grana",
        "price": "6.25"
    },
    {
        "": "",
        "name": "Zola",
        "description": "Pomodoro, mozzarella, zola",
        "price": "6.25"
    },
    {
        "": "",
        "name": "Calzone Farcito",
        "description": "pomodoro, mozzarella, funghi, carciofi, prosciutto, capriccio di verdure",
        "price": "6.25"
    },
    {
        "": "",
        "name": "Calzone 4 Formaggi",
        "description": "pomodoro, mozzarella, brie, zola, grana",
        "price": "6.25"
    },
    {
        "": "",
        "name": "Cassinetta",
        "description": "Pomodoro, mozzarella, salsiccia, peperoni",
        "price": "6.5"
    },
    {
        "": "",
        "name": "Capricciosa",
        "description": "pomodoro, mozzarella, funghi, carciofi, prosciutto cotto, capriccio di verdure, capperi, acciughe, olive",
        "price": "6.75"
    },
    {
        "": "",
        "name": "Verdure",
        "description": "pomodoro, mozzarella, zucchine, melanzane, peperoni, spinaci, pomodori, olive",
        "price": "6.75"
    },
    {
        "": "",
        "name": "Cenzio",
        "description": "pomodoro, mozzarella, funghi, carciofi, prosciutto cotto, capriccio di verdure, salame piccante, capperi, acciughe, olive",
        "price": "7"
    },
    {
        "": "",
        "name": "Crudo e Rucola",
        "description": "pomodoro, mozzarella, crudo, rucola",
        "price": "7"
    },
    {
        "": "",
        "name": "Tirolese",
        "description": "pomodoro, mozzarella, brie, speck",
        "price": "7"
    },
    {
        "": "",
        "name": "Parigina",
        "description": "pomodoro, mozzarella, speck, zola",
        "price": "7"
    },
    {
        "": "",
        "name": "Zola & Mele",
        "description": "mozzarella, zola e mele",
        "price": "7"
    },
    {
        "": "",
        "name": "Ricotta & Spinaci",
        "description": "pomodoro, mozzarella, ricotta e spinaci",
        "price": "7"
    },
    {
        "": "",
        "name": "Saro",
        "description": "pomodoro, mozzarella, salsiccia, bufala",
        "price": "7.25"
    },
    {
        "": "",
        "name": "Parmigiana",
        "description": "pomodoro, mozzarella, bufala, melanzane, grana",
        "price": "7.25"
    },
    {
        "": "",
        "name": "Porcini",
        "description": "pomodoro, mozzarella, porcini",
        "price": "7.25"
    },
    {
        "": "",
        "name": "Pronto Pizza",
        "description": "pomodoro, mozzarella, bufala, rucola, pomodori freschi",
        "price": "7.75"
    },
    {
        "": "",
        "name": "Boscaiola",
        "description": "pomodoro, mozzarella, porcini, speck",
        "price": "8"
    },
    {
        "": "",
        "name": "Mari & Monti",
        "description": "pomodoro, mozzarella, porcini, gamberetti",
        "price": "8"
    },
    {
        "": "",
        "name": "Elia",
        "description": "pomodoro, mozzarella, gamberetti, rucola, grana",
        "price": "8"
    },
    {
        "": "",
        "name": "Pizza del mese",
        "description": "",
        "price": "6.5"
    }
]

  return (
    <div id="menu" className='menu container'>
      <h3 className='title'>Le nostre pizze</h3>
      <div className="menu-main">
      {menu.map((item, index) => (
        <div className="menu-item" key={`menu-item-${index}`}>
            <div className="menu-title-holder">
              <h4 className="menu-title">{item.name}</h4>
              <div className="menu-dots"></div>
              <span className="menu-price">€ {item.price}</span>
            </div>
          <p className="menu-description open">{item.description}</p>
        </div>
      ))}
      </div>
      
    </div>
  );
}

export default Menu;







