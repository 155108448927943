import { useEffect } from 'react';
import './map.scss';

function Map() {

  return (
    <div id="contatti" className='map'>
      <h3 className='title'>Contatti</h3>
      <div className="container">
        <div className="items">
          <div className="item address">
            <h6>indirizzo</h6>
            <a target='_blank' href='https://www.google.com/maps/place//data=!4m2!3m1!1s0x4786914960183de9:0x4e32860777740ef9?sa=X&ved=1t:8290&ictx=111' rel="noreferrer"><span></span><p>Via Larga 6, Saronno</p></a>
          </div>  
          <div className="item hours">
            <h6>orari</h6>
            <ul>
              <li><span>Lunedì</span><p> chiuso</p></li>
              <li><span>Martedì </span><p>chiuso</p></li>
              <li><span>Mercoledì </span><p>8,30 - 13,30, 17,30 - 21,30</p></li>
              <li><span>Giovedì </span> <p>8,30 - 13,30, 17,30 - 21,30</p></li>
              <li><span>Venerdì </span> <p>8,30 - 13,30, 17,30 - 21,30</p></li>
              <li><span>Sabato </span> <p>8,30 - 13,30, 17,30 - 21,30</p></li>
              <li><span>Domenica </span> <p>8,30 - 13,30, 17,30 - 21,30</p></li>
            </ul>
            <p>Chiuso il lunedì e il martedì</p>
          </div>
          <div className="item phone">
            <h6>Telefono</h6>
            <a href="tel:3463486985"><span></span>3463486985</a>
            <a href="tel:029601476"><span></span>02-9601476</a>
          </div>  
        </div> 
      </div>
    </div>
  );
}

export default Map;