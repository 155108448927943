import './header.scss';
import { useEffect, useState } from 'react';
import logo from '../../img/prontopizza.png';

function Header() {
  const initialList = [
    {
      label: 'home',
      link: 'home',
      status: false
    },
    {
      label: 'specialità',
      link: 'special',
      status: false
    },
    {
      label: 'menu',
      link: 'menu',
      status: false
    },
    {
      label: 'contatti',
      link: 'contatti',
      status: false
    },
    {
      label: 'gallery',
      link: 'gallery',
      status: false
    }
  ];
  
  const [list, setList] = useState(initialList) ;
  const [slimHeader, setSlimHeader] = useState(false);
  const [mobileHeader, setMobileHeader] = useState(false);

  const openMobileMenu = () => {
    setMobileHeader(!mobileHeader)
  }

  const goTo = (link) => {
      window.event.preventDefault();
      const targetElement = document.querySelector(`#${link}`);
      if (targetElement) {
        const targetOffset = targetElement.offsetTop;
        window.scrollTo({
          top: targetOffset - 70,
          behavior: 'smooth'
        });
      }      
  }

  const CloseandGo = (link) => {
    setMobileHeader(!mobileHeader);
    goTo(link);
  }


  useEffect(() => {
    const handleScroll = () => {
      if(window.scrollY > 115) setSlimHeader(true)
      else setSlimHeader(false)
    
      const updatedList = list.map(item => {
        const targetElement = document.querySelector(`#${item?.link}`);
        if(targetElement) {
          const targetOffset = targetElement.offsetTop;
          const scrollY = window.scrollY;
          
          if (scrollY >= targetOffset - 124 && scrollY < targetOffset + targetElement.clientHeight - 124) {
            return { ...item, status: true };
          } else {
            return { ...item, status: false };
          }
        }
        return item;
      });
      setList(updatedList);
    };

    window.addEventListener('scroll', handleScroll);

    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

  return (
    <header className={slimHeader ? 'slim-header' : ''}>
      <nav>
        <img width="120px" src={logo} height="auto" alt="Pronto Pizza"/>
        <ul className="items">
          {list.map((item, index) => (
            <li key={index}><span className={item?.status ? 'active' : 'inactive'}></span><p onClick={() => goTo(item?.link)}>{item?.label}</p></li>
          ))}
        </ul>
      </nav>
      <div data-active={mobileHeader} onClick={openMobileMenu} className="icon-menu-mobile">
          <span></span>
          <span></span>
          <span></span>
      </div>
      <ul data-open={mobileHeader} className="items-mobile">
        <img width="120px" src={logo} height="auto" alt="Pronto Pizza"/>
          {list.map((item, index) => (
            <li key={index}><span className={item?.status ? 'active' : 'inactive'}></span><p onClick={() => CloseandGo(item?.link)}>{item?.label}</p></li>
          ))}
      </ul>
    </header>
    
  );
}

export default Header;