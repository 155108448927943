import './special.scss';

function importAll(r) {
  let images = {};
   r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images
 }
 const images = importAll(require.context('../../img', false, /^\.\/icon-.*\.png$/));

function Special() {
  const specialList = [
    {
      name: "Tiramisu",
      image: 'tiramisu',
      price: "3,00"
    },
    {
      name: "Mini arancini",
      image: 'mini-arancini',
      price: "1,50"
    },
    {
      name: "Arancini",
      image: 'arancini',
      price: "2,50"
    },
    {
      name: "Panzerotti",
      image: 'panzerotto',
      price: "3,00"
    },
    {
      name: "Patatine fritte",
      image: 'french-fries',
      price: "2,50/3,50"
    },
    {
      name: "Parmigiana",
      image: 'parmigiana',
      price: "5,00"
    },
    {
      name: "Crespelle",
      image: 'crespelle',
      price: "5,00"
    },
    {
      name: "Lasagna",
      image: 'lasagna',
      price: "5,00"
    },
    {
      name: "Polpettone",
      image: 'polpettone',
      price: "6,00"
    },
    {
      name: "Arrosto",
      image: 'arrosto',
      price: "6,00"
    }
  ]

  return (
    <div id="special" className="container">
      <h3 className='title'>Le nostre specialità</h3>
      <div className="special">
        {specialList?.map((item, index) => (
          <div key={index} className="special-item">
            <div style={{ backgroundImage: `url(${images['icon-' + (item?.image || 'default') + '.png']})` }} className="special-item-icon"></div>
            <h4 className="special-item-name">{item?.name}</h4>
            <div className="special-item-price">{item?.price} €</div>
          </div>
        ))}
      </div>

      <h5 className='special-pastry'>Panettoni e colombe artigianali!</h5>
    </div>
  );
}

export default Special;