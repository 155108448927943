import React from "react";
import './carousel.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';

import "swiper/css";

function Carousel() {
  
  const handleScroll = () => {
    let pageHeight = window.innerHeight;
    let currentPosition = window.pageYOffset;
    let targetPosition = currentPosition + pageHeight;
    window.scrollTo({
      top: targetPosition - 20,
      behavior: 'smooth' 
    });
  };

  return (
    <>
      <Swiper  id="home" loop={true} navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide data-number="1">
          <div className="text">
            <h3>Mercoledì pizze da listino a 5 €</h3>
          </div>
        </SwiperSlide>
        <SwiperSlide data-number="2">
          <div className="text">
            <h3>Ogni mese un gusto nuovo</h3>
          </div>
        </SwiperSlide>
        <SwiperSlide data-number="3">
          <div className="text">
            <h3>Pizza al metro 26€</h3>
          </div>
        </SwiperSlide>
        <SwiperSlide data-number="4">
          <div className="text">
            <h3>Mezzo metro di pizza 15€</h3>
          </div>
        </SwiperSlide>
        <SwiperSlide data-number="5">
          <div className="text">
            <h3>Tutte le domeniche piatto speciale</h3>
          </div>
        </SwiperSlide>
        <div className="scroll">
          <p onClick={handleScroll}>scroll</p>
        </div>
      </Swiper>
  
    </>
  );
}

export default Carousel;
