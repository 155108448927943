import './App.scss';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Carousel from './components/carousel/carousel';
import Menu from './components/menu/menu';
import Map from './components/map/map';
import Gallery from './components/gallery/gallery';
import Special from './components/special/special';

function App() {

  return (
    <div className="App">
      <Header/>
      <Carousel/>
      <Special/>
      <Menu/>
      <Map/>
      <Gallery/>
      <Footer/>
    </div>
  );
}

export default App;
