import { useEffect, useState } from 'react';
import './gallery.scss';


const Gallery = () => {
  const [gallery, setGallery] = useState() ;

  const getInstagramFeed = async () => {
    const accessToken = 'IGQWRNbTNWRGJ5Vnd1T2xKUVl4UjJBRW1mMEg2aXdJOXZABNVFack51c3cyWFNVQkdpTVZACczhOdVNWcG4xWUxVdHdPSlhqMGtuTllYQ055TzFTLW1lWXNlM2VJNHJUeU0xek1ibnBHT0cxa18yZAkZA5RWJESGVmMk0ZD';
    try {
      const response = await fetch(`https://graph.instagram.com/v12.0/me/media?fields=id,caption,media_type,media_url,permalink,timestamp&access_token=${accessToken}`);
      if (!response.ok) {
        throw new Error('Errore durante la richiesta dei dati');
      }
      const data = await response.json();
      return data.data;
    } catch (error) {
      return []; 
    }
  };

  useEffect(() => {
    async function fetchData() {
      const response = await getInstagramFeed();
      setGallery(response.slice(0, 15))
    }
    fetchData();
    if (!gallery) {
      fetchData()
    }
  }, []); 

  return (
    <div id="gallery">
      <h3 className='title'>Vieni a trovarci su instagram</h3>
      <div className='gallery container'>
      {gallery?.map((post, index) => {
        if (post?.media_type !== 'VIDEO') {
          return (
            <a target='_blank' href={post?.permalink} key={index} className={`gallery-item gallery-item-${post?.id}`}>
              <img alt={post?.caption} src={post?.media_url} />
            </a>
          );
        }
        return null;
      })}
      </div>
    </div>
  );
}

export default Gallery;